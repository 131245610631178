import React from 'react'
import { useState } from 'react'
import styles from "./RatingParams.module.css";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomTextarea from '../../components/CustomTextarea/CustomTextarea';
import DatePicker from 'react-datepicker'
import moment from "moment/moment";
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { getYearsArr } from '../../utils/yunarmyStat';
import Modal from "react-responsive-modal";
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useEffect } from 'react';

const defaultValidationSchema = Yup.object().shape({
    name: Yup.string().required("поле является обязательным"),
    description: Yup.string().required("поле является обязательным"),
    year: Yup.number().required("поле является обязательным"),
    closedate: Yup.string().required("поле является обязательным"),
});

const ModalAdd = (
    {
    loading,
    setModalAdd,
    onClose,
    addRating,
    editRating,
    data,
    type,
    ...props
      }
) => {

    const [closedDate, setClosedDate] = useState(null)    

    const closedDateChange = (date) => {
        setClosedDate(date)
        const formatDate = date?.format("YYYY-MM-DD") || '';
        
        formik.setFieldValue('closedate', formatDate)
    }

    useEffect(() => {
      if (data && data?.closedate && type === 'EDIT') {
        setClosedDate(moment(data?.closedate))
      }
    }, [data])
    
    
    const handleSubmitForm = (values) => {
      
        if (type === 'EDIT') {
          editRating(values, data.id);
        } else {
          addRating(values);
        }

        onClose();
    }

    const formik = useFormik({
        initialValues: (type === "EDIT" ? 
        {name: data.name, description: data.description, closedate: data.closedate, year: data.year} : 
        {name:'', description:'', closedate: '', year: ''}),
        validationSchema: defaultValidationSchema,
        onSubmit: values => handleSubmitForm(values)
    });

    const {values, errors, handleChange, handleSubmit} = formik;

    return (
    <Modal
      onClose={onClose}
      open={props.open}
      classNames={{
        modal: styles.modalAdd,
      }}
      showCloseIcon={false}
    >
    <div className={styles.title}>{type === "EDIT" ? "Изменить" : "Создать"} параметры рейтинга</div>
    <div className={styles.modalAddBody}>
        
        <div style={{marginBottom:".5rem"}}>
        <CustomInput
        name="name"
        value={values.name}
        title="Название"
        onChange={handleChange}
        validate={errors.name}
        />
        </div>

        <div style={{marginBottom:".5rem"}}>
        <CustomTextarea
        name="description"
        value={values.description}
        title="Описание"
        onChange={handleChange}
        validate={errors.description}
        height="100px"
        fontSize="16px"
        />
        </div>

        <div className={styles.dateTitle}>Дата окончания:</div>
        
        <DatePicker
        className={styles.dataBlock}
        selected={closedDate}
        name='closedate'
        dateFormat="YYYY-MM-DD"
        onChange={closedDateChange}
        locale={"ru"}/>
        
        {!!errors.closedate && <div className={styles.validate}>! поле является обязательным</div>}
        
        <div style={{marginTop:".5rem"}}>
        <CustomSelect
        title="Год"
        options={getYearsArr(2016).map(el => ({name:el,value:el}))}
        name="year"
        selectedValue={values.year}
        defaultValue={values.year}
        onChange={handleChange}
        validate={errors.year}
        border={false}
        />
        </div>
    </div>
    <div className={styles.buttonBlock}>
    <ButtonRed style={{margin: '1rem'}} onClick={handleSubmit}>Сохранить</ButtonRed>
    <ButtonRed style={{margin: '1rem'}} onClick={onClose}>Закрыть</ButtonRed>
    </div>
    </Modal>
  )
}

export default ModalAdd