import {
  BACKEND_URL_PREFIX,
  yunarmiansOnPage,
  yunarmiansOnPageList,
} from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import {
  SET_UNARMIAN_PAGE_NUMBER,
  SET_UNARMIAN_IMAGES,
  SET_UNARMIANS_COUNT,
  SET_UNARMIANS_DATA,
  SET_UNARMIAN_PROFILE,
  SET_UNARMIAN_LOADING,
  SET_FILTERED_REGIONS,
  REMOVE_REGIONS_FILTER,
  REMOVE_LOCAL_DEP_FILTER,
  SET_FILTERS,
  CLEAR_UNARMIAN_PROFILE,
  SET_UNARMIAN_NEW_ID,
  SET_FILTERED_LOCAL_DEP,
  SET_ALL_UNARMIANS_DATA,
  SET_UNARMIAN_PDF_LOADING,
  SET_UNARMIAN_SPORT,
  SET_UNARMIAN_ACTIVITY,
  GET_TROOP_HISTORY,
  GET_TROOP_LIST,
  ERROR,
  SET_UNARMIAN_BACK_UPDATE,
  GET_DEPS_LIST,
  SET_UNARMIAN_DOCS_INFO,
  SET_UNARMIAN_MODAL_LOADING,
  SET_CUSTOM_DOCS_INFO,
  SET_COUNTRY,
  SET_FILTERED_COUNTRY,
  REMOVE_COUNTRY_FILTER,
  SET_AWARD_PROFILE,
  DELETE_FILTERS,
  SET_ACTIVITY_TYPES
} from "./types.js";
import { createNotification } from "../components/Notifications/Notifications";
import moment from "moment";

export const removeFilteredGroup = (groupName) => ({
  type: REMOVE_REGIONS_FILTER
});

export const removeFilteredGroupCountry = (groupName) => ({
  type: REMOVE_COUNTRY_FILTER
});

export const backUploadToggle = (data) => ({
  type: SET_UNARMIAN_BACK_UPDATE,
  payload: data
});

export const removeFilteredLocalDep = () => ({
  type: REMOVE_LOCAL_DEP_FILTER
});
export const setFilteredGroups = (data) => ({
  type: SET_FILTERS,
  payload: data,
});
export const deleteFilteredGroups = (data) => ({
  type: DELETE_FILTERS
});
export const setNumberPage = (data) => ({
  type: SET_UNARMIAN_PAGE_NUMBER,
  payload: data,
});
export const removeDataForPrint = () => ({
  type: SET_ALL_UNARMIANS_DATA,
  payload: null
});

export const removeTroopHistory = () => ({
  type: GET_TROOP_HISTORY,
  payload: null
});

export const removeTroopList = () => ({
  type: GET_TROOP_LIST,
  payload: null
});

export const clearProfile = () => ({ type: CLEAR_UNARMIAN_PROFILE });

export const clearUnarmiansData = () => ({type: SET_UNARMIANS_DATA, payload: null})

export const getYunarmiansCount = (filters = null, listView = false) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const profilesOnPage = listView ? yunarmiansOnPageList : yunarmiansOnPage;
      const params = {};
      if (filters) {
        filters.nophone && (params.nophone = 1);
        filters.noemail && (params.noemail = 1);
        filters.is_adult && (params.is_adult = 1);
        filters.nodep && (params.no_domestic_department = 1);
        filters.nodet && (params.no_detachment = 1);
        filters.created_by_mobile && (params.created_by_mobile = true);
        filters.month_no_use_mobile && (params.month_no_use_mobile = true);
        filters.has_mobile && (params.has_mobile = true);
        filters.no_mobile && (params.no_mobile = true);
        filters.noNotif && (params.no_initial_notification = 1);
        filters.noCert && (params.has_no_certificate = 1);
        filters.sex && (params.sex = filters.sex);
        filters.status && (params.status = filters.status);
        filters.regions.length && (typeof filters.regions === 'string' ? (params.regions = filters.regions) : params.regions = filters.regions.map((it) => Number(it.id)).join(","));
        if (filters.abroad == true && filters.country.length == 0) {
          (params.country_ids = -1);
        } else {
          filters.country?.length && (params.country_ids = filters.country.map((it) => Number(it.id)).join(","));
        }
        filters.localDep?.length && (params.domestic_department_id = filters.localDep.map((it) => Number(it.id)).join(","));
        filters.regional_department_ids?.length && (params.regional_department_ids = filters.regional_department_ids.join());
        filters.birthday_year && (params.birthday_year = filters.birthday_year)
        filters.unaprofile_type && (params.unaprofile_type = filters.unaprofile_type)
        filters.lastName && (params.lastName = filters.lastName.trim());
        filters.firstName && (params.firstName = filters.firstName.trim());
        filters.fathersName && (params.fathersName = filters.fathersName.trim());
        filters.school && (params.school = filters.school);
        filters.detachment && (params.detachment = filters.detachment);
        filters.age?.length && (params.age = filters.age.join());
        filters.email && (params.email = filters.email);
        filters.personal_id && (params.personal_id = filters.personal_id);
        filters.city && (params.city = filters.city);
        filters.from_date && (params.from_date = moment(filters.from_date).format("YYYY-MM-DD"))
        filters.to_date && (params.to_date = moment(filters.to_date).format("YYYY-MM-DD"))
        filters.createdAt && (params.createdAt = moment(filters.createdAt).format("YYYY-MM-DD"))
        filters.createdAtTo && (params.createdAtTo = moment(filters.createdAtTo).format("YYYY-MM-DD"))
        filters.profile_filled_from_date && (params.profile_filled_from_date = moment(filters.profile_filled_from_date).format("YYYY-MM-DD"))
        filters.profile_filled_to_date && (params.profile_filled_to_date = moment(filters.profile_filled_to_date).format("YYYY-MM-DD"))
        filters.on_date && (params.on_date = moment(filters.on_date).format("YYYY-MM-DD"))
        filters.is_age && (params.is_age = Number(filters.is_age))
      }
      if (params.regional_department_ids == '') {
        delete params.regional_department_ids
      } 
      const urlParams = new URLSearchParams(params).toString();

      const response = await fetch(
        `${BACKEND_URL_PREFIX}/unaprofile/count?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        const pageCount = Math.ceil(data / profilesOnPage);
        dispatch({
          type: SET_UNARMIANS_COUNT,
          payload: { data: data, pageCount: pageCount },
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const getYunarmiansData =
  (page = 1, filters = null, listView = false, all=false) =>
  async (dispatch, getState) => {
    !all && dispatch({ type: SET_UNARMIAN_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      !all && await dispatch(getYunarmiansCount(filters, listView));
      const profilesOnPage = listView ? yunarmiansOnPageList : yunarmiansOnPage;
      const offset = page * profilesOnPage - profilesOnPage;
      const params = all ? {} : {
        offset: offset,
        limit: profilesOnPage,
      };
      if (filters) {
        filters.nophone && (params.nophone = 1);
        filters.noemail && (params.noemail = 1);
        filters.is_adult && (params.is_adult = 1);
        filters.nodep && (params.no_domestic_department = 1);
        filters.nodet && (params.no_detachment = 1);
        filters.created_by_mobile && (params.created_by_mobile = true);
        filters.has_mobile && (params.has_mobile = true);
        filters.month_no_use_mobile && (params.month_no_use_mobile = true);
        filters.no_mobile && (params.no_mobile = true);
        filters.noNotif && (params.no_initial_notification = 1);
        filters.noCert && (params.has_no_certificate = 1);
        filters.sex && (params.sex = filters.sex);
        filters.status && (params.status = filters.status);
        filters.regions.length && (params.regions = filters.regions.map((it) => Number(it.id)).join(","));
        if (filters.abroad == true && filters.country.length == 0) {
                                    (params.country_ids = -1);
        } else {
          filters.country.length && (params.country_ids = filters.country.map((it) => Number(it.id)).join(","));
        }
        filters.localDep.length && (params.domestic_department_id = filters.localDep.map((it) => Number(it.id)).join(","));
        filters.age.length && (params.age = filters.age.join());
        filters.birthday_year && (params.birthday_year = filters.birthday_year)
        filters.unaprofile_type && (params.unaprofile_type = filters.unaprofile_type)
        filters.regional_department_ids  && (params.regional_department_ids  = filters.regional_department_ids )
        filters.lastName && (params.lastName = filters.lastName.trim());
        filters.firstName && (params.firstName = filters.firstName.trim());
        filters.fathersName && (params.fathersName = filters.fathersName.trim());
        filters.school && (params.school = filters.school);
        filters.detachment && (params.detachment = filters.detachment);
        filters.email && (params.email = filters.email);
        filters.personal_id && (params.personal_id = filters.personal_id);
        filters.city && (params.city = filters.city);
        filters.from_date && (params.from_date = moment(filters.from_date).format("YYYY-MM-DD"))
        filters.to_date && (params.to_date = moment(filters.to_date).format("YYYY-MM-DD"))
        filters.createdAt && (params.createdAt = moment(filters.createdAt).format("YYYY-MM-DD"))
        filters.createdAtTo && (params.createdAtTo = moment(filters.createdAtTo).format("YYYY-MM-DD"))
        filters.profile_filled_from_date && (params.profile_filled_from_date = moment(filters.profile_filled_from_date).format("YYYY-MM-DD"))
        filters.profile_filled_to_date && (params.profile_filled_to_date = moment(filters.profile_filled_to_date).format("YYYY-MM-DD"))
        filters.on_date && (params.on_date = moment(filters.on_date).format("YYYY-MM-DD"))
        filters.is_age && (params.is_age = Number(filters.is_age))
      }
      
      if (params.regional_department_ids == '') {
        delete params.regional_department_ids
      } 

      const urlParams = new URLSearchParams(params).toString();

      const response = await fetch(
        `${BACKEND_URL_PREFIX}/unaprofile?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({type: SET_UNARMIAN_BACK_UPDATE, payload: false});
        all ?
        dispatch({ type: SET_ALL_UNARMIANS_DATA, payload: data }) : 
        dispatch({ type: SET_UNARMIANS_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({ type: SET_UNARMIAN_LOADING, payload: false });
  };

  export const getAllYunarmiansData = (searchFilters) => async (dispatch, getState) => {
    
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

      try {

        dispatch({type: SET_UNARMIAN_PDF_LOADING, payload: true})
        const filters = getState().yunarmians.filtered
        await dispatch(getYunarmiansCount(searchFilters || filters))
               
        const count = getState().yunarmians.count
        if (count && count > 4000) return createNotification("error", "Список слишком большой, выберите меньшее количество профилей")
          
          const params = searchFilters || {}
          if (!searchFilters) {
            filters.nophone && (params.nophone = 1);
            filters.noemail && (params.noemail = 1);
            filters.is_adult && (params.is_adult = 1);
            filters.nodep && (params.no_domestic_department = 1);
            filters.created_by_mobile && (params.created_by_mobile = true);
            filters.has_mobile && (params.has_mobile = true);
            filters.month_no_use_mobile && (params.month_no_use_mobile = true);
            filters.no_mobile && (params.no_mobile = true);
            filters.nodet && (params.no_detachment = 1);
            filters.noNotif && (params.no_initial_notification = 1);
            filters.noCert && (params.has_no_certificate = 1);
            filters.sex && (params.sex = filters.sex);
            filters.status && (params.status = filters.status);
            filters.regions.length && (params.regions = filters.regions.map((it) => Number(it.id)).join(","));
            
            if (filters.abroad == true && filters.country.length == 0) {
              (params.country_ids = -1);
            } else {
              filters.country.length && (params.country_ids = filters.country.map((it) => Number(it.id)).join(","));
            }
            filters.localDep.length && (params.domestic_department_id = filters.localDep.map((it) => Number(it.id)).join(","));
            filters.age.length && (params.age = filters.age.join());
            filters.birthday_year && (params.birthday_year = filters.birthday_year)
            filters.unaprofile_type && (params.unaprofile_type = filters.unaprofile_type)
            filters.regional_department_ids  && (params.regional_department_ids  = filters.regional_department_ids )
            filters.lastName && (params.lastName = filters.lastName.trim());
            filters.firstName && (params.firstName = filters.firstName.trim());
            filters.fathersName && (params.fathersName = filters.fathersName.trim());
            filters.school && (params.school = filters.school);
            filters.detachment && (params.detachment = filters.detachment);
            filters.email && (params.email = filters.email);
            filters.personal_id && (params.personal_id = filters.personal_id);
            filters.city && (params.city = filters.city);
            filters.from_date && (params.from_date = moment(filters.from_date).format("YYYY-MM-DD"))
            filters.to_date && (params.to_date = moment(filters.to_date).format("YYYY-MM-DD"))
            filters.createdAt && (params.createdAt = moment(filters.createdAt).format("YYYY-MM-DD"))
            filters.createdAtTo && (params.createdAtTo = moment(filters.createdAtTo).format("YYYY-MM-DD"))
            filters.profile_filled_from_date && (params.profile_filled_from_date = moment(filters.profile_filled_from_date).format("YYYY-MM-DD"))
            filters.profile_filled_to_date && (params.profile_filled_to_date = moment(filters.profile_filled_to_date).format("YYYY-MM-DD"))
            filters.on_date && (params.on_date = moment(filters.on_date).format("YYYY-MM-DD"))
            filters.is_age && (params.is_age = Number(filters.is_age))
          }
          // if(Object.keys(params).length) {
            if (params.regional_department_ids == '') {
              delete params.regional_department_ids
            } 
            
            const urlParams = new URLSearchParams(params).toString();
    
            const response = await fetch(
              `${BACKEND_URL_PREFIX}/unaprofile?${urlParams}`,
              {
                method: "GET",
                ...buildDefaultHeaders(getState),
              }
            );
            if (response.ok) {
              const data = await response.json();
              dispatch({ type: SET_ALL_UNARMIANS_DATA, payload: data })
              
            } else {
              dispatch({ type: ERROR, payload: response });
            }
          // } else {
          //   createNotification("error", "Необходимо применить хотя бы 1 фильтр")
          // }
        } catch {

        } finally {
          dispatch({type: SET_UNARMIAN_PDF_LOADING, payload: false})
        }
      }
  };

export const getYunarmianProfile = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_UNARMIAN_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      if (data && data.UnaProfile[0].email == null) {
        data.UnaProfile[0].email = '';
      }
      if (data && data.UnaProfile[0].phone == null) {
        data.UnaProfile[0].phone = '';
      }
      console.log('pered')
      await dispatch({ type: SET_UNARMIAN_PROFILE, payload: data.UnaProfile[0] });
      console.log('posle')
    } else {
      dispatch({ type: ERROR, payload: response });
    }

    const photos = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/${id}/files`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (photos.ok) {
      const data = await photos.json();
      dispatch({ type: SET_UNARMIAN_IMAGES, payload: data.UnaProfileFiles });
    } else {
      dispatch({ type: ERROR, payload: response });
    }

    const sport = await fetch(`${BACKEND_URL_PREFIX}/sport_activity/profile/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (sport.ok) {
      const data = await sport.json();
      dispatch({ type: SET_UNARMIAN_SPORT, payload: data.sport_activities });
    } else {
      dispatch({ type: ERROR, payload: response });
    }

    const activity = await fetch(`${BACKEND_URL_PREFIX}/activity/profile/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (activity.ok) {
      const data = await activity.json();
      dispatch({ type: SET_UNARMIAN_ACTIVITY, payload: data.activities });
    } else {
      dispatch({ type: ERROR, payload: response });
    }

    dispatch({ type: SET_UNARMIAN_LOADING, payload: false });
  }
};

export const getFilteredGroup =
  (groupName = "regions", string) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/${groupName}?filter=${string}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        let data = await response.json();
        
        if (groupName == 'country') {
          dispatch({ type: SET_FILTERED_COUNTRY, payload: data?.countries?.map(el => ({regionId: el.global_id, id: el.id, name: el.full_name, short_name: el.short_name, code: el.code})) });
        } else {
          dispatch({ type: SET_FILTERED_REGIONS, payload: data });
        }

      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
};

export const getFilteredLocalDep =
  (string) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/domestic_department?search=${string.toLowerCase()}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_FILTERED_LOCAL_DEP, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
};

export const editYunarmianProfile =
  (id, data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      delete data.region_name
      delete data.files
      if(data.trp_grade == '') {data.trp_grade = null}
      if(data.trp_result == '') {data.trp_result = null}
      if(data.trp_cert_number == '') {data.trp_cert_number = null}
      // data.birthday = moment(data.birthday).format("YYYY-MM-DD")
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/${id}`, {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          console.log("ok");
          await dispatch(getYunarmianProfile(id))
        } else {
          dispatch({ type: ERROR, payload: response });
          if (response.status===400) {
            const err = await response.json()
            createNotification("error", err.error)
          }
          if (response.status===409) {
            const err = await response.json()
            createNotification("error", err.error)
            dispatch({ type: CLEAR_UNARMIAN_PROFILE });
            await dispatch(getYunarmianProfile(id))
          }
        }
      } catch (ERR) {
        console.log(ERR)
      }
    }
  };

export const addYunarmianProfile =
  (data, history, TroopId, awards) => async (dispatch, getState) => {
   
    await dispatch(checkAndUpdateToken());
    if(data.trp_grade == '0') {data.trp_grade = null}
    if(data.trp_result == '0') {data.trp_result = null}
    if (getState().auth.token) {
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: SET_UNARMIAN_NEW_ID, payload: responseData.id});
            createNotification("success", "Пользователь успешно добавлен");
            history.push("/unarmiyans_list");
            
            if(TroopId) {
              dispatch(transferTroop(responseData.id, TroopId));
            }

            if (awards.length > 0) {
              awards.forEach(el => 
                dispatch(addAwardProfile(responseData.id, el.id, el))
                )
            }
        } else {
          dispatch({ type: ERROR, payload: response });
          createNotification("error", responseData.error || 'Ошибка добавления профиля');
          // responseData.error.includes("Введенный адрес эл.почты") && createNotification("error", responseData.error); 
          // responseData.error.includes("Недопустимые символы в ФИО") && createNotification("error", "Недопустимые символы в ФИО (используйте кириллицу)");
          // responseData.error.includes("Такой юнармеец уже есть") && createNotification("error", "Такой юнармеец уже есть в базе данных, повторное добавление запрещено.");
          }
        } catch (ERR) {
      }
    }
  };

  export const deleteProfile = (id) => async (dispatch, getState) => {
    dispatch({ type: SET_UNARMIAN_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        await fetch(`${BACKEND_URL_PREFIX}/unaprofile/${id}/imported`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        });
    }
  }

  export const uploadDocs =
  (id, image, type, rotate) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", image.file);
    fd.append("group", type);
    fd.append("rotate", rotate%360);

    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/unaprofile/${id}/upload_document`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
        }
      );
      if (response.ok) {
        const data = await response.json()
        if (data.error) {
          createNotification('error', `Выбранное Вами изображение уже существует`)
        }
        // dispatch(getYunarmianProfile(id));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteDocs = (id, docId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/unaprofile/${id}/delete_document/${docId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getYunarmianProfile(id));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getTroopHistory =
  (id) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/unaprofile/${id}/detachment_history`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: GET_TROOP_HISTORY, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
};

export const getDeps = (parent = 0) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {

      const response = await fetch(`${BACKEND_URL_PREFIX}/department?parent=${parent}&detachment=${false}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: GET_DEPS_LIST, payload: data });
          } else {
            dispatch({ type: ERROR, payload: response });
          }
    }
}

export const getTroops = (parent = 0) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {

      const response = await fetch(`${BACKEND_URL_PREFIX}/department?parent=${parent}&detachment=${true}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: GET_TROOP_LIST, payload: data });
          } else {
            dispatch({ type: ERROR, payload: response });
          }
    }
}

export const transferTroop =
  (id, troopId) => async (dispatch, getState) => {
   
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/${id}/attach_to_detachment`, {
          method: "POST",
          body: JSON.stringify({detachment_id: troopId}),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          dispatch(getYunarmianProfile(id));
        } else {
          dispatch({ type: ERROR, payload: response });
          }
        } catch (ERR) {
      }
    }
  };

  export const editYunarmianGroup =
  (regDep, date, status, domestic_department_id, detachment_id, profile_ids, organiz, city, page) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const data = {profile_ids: profile_ids}
        status && (data.status = status)
        domestic_department_id && (data.domestic_department_id = domestic_department_id)
        detachment_id && (data.detachment_id = detachment_id)
        organiz && (data.school = organiz)
        city && (data.city = city)
        regDep && (data.regional_department_id = regDep)
        date && (data.sign_date = date)

        const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/bulk_update`, {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          const filters = getState().yunarmians.filtered
          dispatch(getYunarmiansData(page, filters))
        } else {
          dispatch({ type: ERROR, payload: response });
          if (response.status === 400) {
            const responseData = await response.json()
            const errorText = responseData.error || 'Ошибка группового изменения' 
            const usersInfo = responseData.users?.map(
              ({first_name, last_name, error_message}) => `${first_name} ${last_name} (${error_message})`
            )?.join(', ') || ''
            createNotification('error', `${errorText} ${usersInfo}`)
          }
        }
      } catch (ERR) {
      }
    }
  };

  export const rotateDocumentImage = (docId, deg) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/update_document/${docId}`, {
          method: "PUT",
          body: JSON.stringify({rotate: `${deg}`}),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          console.log("ok")
        } else {
          dispatch({ type: ERROR, payload: response });
          }
        } catch (ERR) {
      }
    }
  }

  export const sendDocuments = (profileId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const data = {users: profileId}
        const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_docs`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
          createNotification('success', 'Документы успешно отправлены')
          dispatch(getDocsInfo(profileId))
        } else {
          createNotification('error', 'Ошибка отправки документов')
          dispatch({ type: ERROR, payload: response });
        }
      } catch (ERR) {
          createNotification('error', 'Ошибка отправки документов')
      }
    }
  }
  
  export const massiveSendDocuments = (profilesList) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const data = {users: profilesList.join(',')}
        const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_docs`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
          createNotification('success', 'Документы успешно отправлены')
        } else {
          createNotification('error', 'Ошибка отправки документов')
          dispatch({ type: ERROR, payload: response });
        }
      } catch (ERR) {
          createNotification('error', 'Ошибка отправки документов')
      }
    }
  }

  export const getDocsInfo = (profileId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
  
        const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_docs/${profileId}`, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              dispatch({ type: SET_UNARMIAN_DOCS_INFO, payload: data.docs_info });
            } else {
              dispatch({ type: ERROR, payload: response });
            }
      }
  }

  export const massiveSendCustomDocument = (data, files) => async (dispatch, getState) => {
    dispatch({type: SET_UNARMIAN_MODAL_LOADING, payload: true})
    try {
    const fd = new FormData()
    Object.keys(data).forEach(key => fd.append(key, data[key]))
    files.forEach((file, ind) => {
      if (!file.size) {
        createNotification('error', 'Невозможна отправка пустого файла')
      } else {
        fd.append(`file_${ind+1}`, file)
      }
    })
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_docs/custom`, {
          method: "POST",
          body: fd,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        }
        });
        if (response.ok) {
          createNotification('success', 'Письмо успешно отправлено')
          return true
        } else {
          createNotification('error', 'Ошибка отправки письма')
          dispatch({ type: ERROR, payload: response });
        }
      }
    } catch (ERR) {
        createNotification('error', 'Ошибка отправки письма')
    } finally {
      dispatch({type: SET_UNARMIAN_MODAL_LOADING, payload: false})
    }
  }

  export const getCustomDocsInfo = () => async (dispatch, getState) => {
    dispatch({type: SET_UNARMIAN_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
  
        const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_docs/custom`, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              dispatch({ type: SET_CUSTOM_DOCS_INFO, payload: data.custom_docs_info.reverse() });
            } else {
              dispatch({ type: ERROR, payload: response });
            }
    }
    dispatch({type: SET_UNARMIAN_LOADING, payload: false})
  }

  export const getCountry = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
  
        const response = await fetch(`${BACKEND_URL_PREFIX}/country`, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              const newData = data?.countries?.map(el => ({regionId: el.global_id, id: el.id, name: el.full_name, short_name: el.short_name, code: el.code}))
              dispatch({ type: SET_COUNTRY, payload: newData });
            } else {
              dispatch({ type: ERROR, payload: response });
            }
    }
  }

  export const getAwardsPerson = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
  
        const response = await fetch(`${BACKEND_URL_PREFIX}/profile_to_departmental_award/profile/${id}`, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              dispatch({ type: SET_AWARD_PROFILE, payload: data.profile_to_departmental_award });
            } else {
              dispatch({ type: ERROR, payload: response });
            }
    }
  }

  export const deleteAwardsPerson = (idProfile, idAward, status) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/profile_to_departmental_award/profile/${idProfile}/award/${idAward}`, {
          method: "PUT",
          body: JSON.stringify({status: !status}),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          createNotification('success', 'Успешно');
          dispatch(getAwardsPerson(idProfile));
        } else {
          dispatch({ type: ERROR, payload: response });
          }
        } catch (ERR) {
          createNotification('error', 'Ошибка')
      }
    }
  }

  export const editAwardsPerson = (dataList, idProfile, idAward, award) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const {id, ...payload} = award
      const awardId = dataList.find(el => el.id == idAward)?.departmental_award_id;
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/profile_to_departmental_award/profile/${idProfile}/award/${awardId}`, {
          method: "PUT",
          body: JSON.stringify(payload),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          createNotification('success', 'Успешно');
        } else {
          const { error } = await response.json()
          error && createNotification('error', error);
          dispatch({ type: ERROR, payload: response });
        }
      } catch (ERR) {
          console.log(ERR)
          createNotification('error', 'Ошибка');
      }
    }
  }

  export const addAwardProfile = (idProfile, idAward, data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/profile_to_departmental_award/profile/${idProfile}/award/${idAward}`, {
          method: "POST",
          body: JSON.stringify({order_number: data.order_number, date_assigment: data.date_assigment}),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          dispatch(getYunarmianProfile(idProfile));
          createNotification('success', 'Успешно');
        } else {
          dispatch({ type: ERROR, payload: response });
          const respData = await response.json()
            createNotification('error', respData.error);  
        }
        } catch (ERR) {
      }
    }
  };

  export const getPortfolio = (yunId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (!getState().auth.token) return;
    let returnState
    try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/profile/portfolio/${yunId}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const {path} = await response.json();
            returnState = path
        }
    } catch (ERR) {
        
    } finally {
      return returnState
    }  
  }
export const getActivityTypes = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;
  const response = await fetch(`${BACKEND_URL_PREFIX}/activity_type`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
  });
  if (response.ok) {
      const { activity_types } = await response.json();
      dispatch({ type: SET_ACTIVITY_TYPES, payload: activity_types });
  }  
}

export const createSportActivity = (data) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  const { files, ...payload } = data
  const profileId = getState().yunarmians.profile.id

  const response = await fetch(`${BACKEND_URL_PREFIX}/sport_activity`, {
      method: "POST",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState)
  });

  if (response.ok) {
    const respData = await response.json()

    await Promise.all(files?.map(file => dispatch(addYunaFile('sport_activity', respData.sport_activity.id, file))))

    dispatch(getYunarmianProfile(profileId))
  }

}

export const updateSportActivity = (activId, data, prevData) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  const { files, ...payload } = data
  const profileId = getState().yunarmians.profile.id

  const response = await fetch(`${BACKEND_URL_PREFIX}/sport_activity/${activId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState)
  });

  if (response.ok) {

    const actualIds = files?.filter((file) => file.id).map((file) => file.id)

    const filesForDelete = prevData.files?.filter(file => !actualIds?.includes(file.id))
    const filesForUpload = files?.filter(file => !file.id)

    await Promise.all(filesForDelete?.map(file => dispatch(deleteYunaFile('sport_activity', file.id))))
    await Promise.all(filesForUpload?.map(file => dispatch(addYunaFile('sport_activity', activId, file))))

    dispatch(getYunarmianProfile(profileId))
  }

}

export const deleteSportActivity = (activId) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  const profileId = getState().yunarmians.profile.id

  const response = await fetch(`${BACKEND_URL_PREFIX}/sport_activity/${activId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState)
  });

  if (response.ok) {
    dispatch(getYunarmianProfile(profileId))
  }
  
}

export const createActivity = (data) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  const { article_files, ...payload } = data
  const profileId = getState().yunarmians.profile.id

  const response = await fetch(`${BACKEND_URL_PREFIX}/activity`, {
      method: "POST",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState)
  });

  if (response.ok) {
    const respData = await response.json()

    await Promise.all(article_files?.map(file => dispatch(addYunaFile('activity', respData.activity.id, file))))

    dispatch(getYunarmianProfile(profileId))
  }

}

export const updateActivity = (activId, data, prevData) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  const { article_files, ...payload } = data
  const profileId = getState().yunarmians.profile.id

  const response = await fetch(`${BACKEND_URL_PREFIX}/activity/${activId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState)
  });

  if (response.ok) {

    const actualIds = article_files?.filter((file) => file.id).map((file) => file.id)

    const filesForDelete = prevData.article_files?.filter(file => !actualIds?.includes(file.id))
    const filesForUpload = article_files?.filter(file => !file.id)

    await Promise.all(filesForDelete?.map(file => dispatch(deleteYunaFile('activity', file.id))))
    await Promise.all(filesForUpload?.map(file => dispatch(addYunaFile('activity', activId, file))))

    dispatch(getYunarmianProfile(profileId))
  }

}

export const deleteActivity = (activId) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  const profileId = getState().yunarmians.profile.id

  const response = await fetch(`${BACKEND_URL_PREFIX}/activity/${activId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState)
  });

  if (response.ok) {
    dispatch(getYunarmianProfile(profileId))
  }
  
}


export const addYunaFile = (entity, entityId, file) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  const fd = new FormData()
  fd.append('file', file)

  await fetch(`${BACKEND_URL_PREFIX}/${entity}/${entityId}/file`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      }
  });
}

export const deleteYunaFile = (entity, fileId) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (!getState().auth.token) return;

  await fetch(`${BACKEND_URL_PREFIX}/${entity}/file/${fileId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState)
  });
}
