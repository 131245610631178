import React, {useState, useEffect} from "react";
import styles from "./Auth.module.css";
// import label from "../../assets/images/label.png";
import avatar from "../../assets/images/default.png"
import AuthForm from "../../components/AuthForm/AuthForm";
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { loadUser, sso, signOut, auth } from '../../actions/auth';


function Auth({authAction, history, token, error, user, loading}) {
  
    
  if (token && user) {
    return <Redirect to='/home' />
  }
  
  return (
    <div className={styles.content}>
          <div className={styles.avatar}>
              <img src={avatar} alt="о_О" />
          </div>
          <AuthForm
            loading={loading}
            history={history}
            authAction={(values, history) => authAction(values, history)}
          />
    </div>
  );
}


export default withRouter(connect(
  (
    ({ auth: { token, user, error, loading } }) => ({ token, user, error, loading })
  ), { loadUser, sso, signAuthAction: signOut, authAction: auth },
)(Auth))