import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";
import reducer from "./reducers";
import requestMiddleware from "./actions/requestMiddleware";
import { NotificationContainer } from 'react-notifications'
import Routes from "./routing/Routes";

import "./components/Layout/global.css"
import 'react-notifications/lib/notifications.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(thunk, requestMiddleware)
  )
);

const persistor = persistStore(store);


function App() {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <Routes />
        <NotificationContainer />
      </Provider>
    </PersistGate>
  );
}

export default App;
